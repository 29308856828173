@import "reset-css";

html {
  height: 100%;
}

body {
  background-color: #2c3a47;
  color: #e5e5e5;

  min-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#root {
  width: 100%;

  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 120%;
}

svg {
  overflow: visible;
}

strong {
  font-weight: 700;
}
